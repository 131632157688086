// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from './AuthContext';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars, faSearch, faFilePdf } from '@fortawesome/free-solid-svg-icons';
// import Spinner from 'react-bootstrap/Spinner';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import { DataGrid } from '@mui/x-data-grid';
// import Skeleton from '@mui/material/Skeleton';
// import Box from '@mui/material/Box';
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   IconButton,
//   InputBase,
//   Grid,
//   Container,
//   Paper,
//   FormControl,
//   OutlinedInput,
//   Button,
//   TextField,
//   InputLabel,
// } from '@mui/material';

// const Dashboard = () => {
//   const { user, logout, setUser } = useAuth();
//   const [formData, setFormData] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(10);
//   const [selectedDocument, setSelectedDocument] = useState(null);
//   const [comments, setComments] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [commentInput, setCommentInput] = useState('');
//   const [viewPdfTab, setViewPdfTab] = useState(false);
//   const [showSkeleton, setShowSkeleton] = useState(true);
//   const [pdfLoading, setPdfLoading] = useState(false); // Track PDF loading
//   const [selectedUserName, setSelectedUserName] = useState('');
//   const [commentSortOrder, setCommentSortOrder] = useState('desc');
//   const [activeTab, setActiveTab] = useState('user-details'); // Active tab state
//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedUser = localStorage.getItem('user');
//     if (storedUser) {
//       setUser(JSON.parse(storedUser));
//     } else {
//       navigate('/login');
//     }
//   }, [navigate, setUser]);

//   useEffect(() => {
//     if (user) {
//       fetchData();
//     }
//   }, [user]);

//   useEffect(() => {
//     if (user) {
//       localStorage.setItem('user', JSON.stringify(user));
//     }
//   }, [user]);

//   const fetchData = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get('https://testscanner.workrspot.com/get-forms');
//       // const response = await axios.get('http://localhost:5000/get-forms');
//       const formData = response.data || [];

//       const commentPromises = formData.map(async (data) => {
//         const commentResponse = await axios.get(`https://testscanner.workrspot.com/get-comment/${data.id}`);
//         // const commentResponse = await axios.get(`http://localhost:5000/get-comment/${data.id}`);
//         return { ...data, comment: commentResponse.data };
//       });

//       const formDataWithComments = await Promise.all(commentPromises);

//       setFormData(formDataWithComments);
//       setLoading(false);
//       setShowSkeleton(false);
//     } catch (error) {
//       console.error(error);
//       setLoading(false);
//     }
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('user');
//     logout();
//     navigate('/login');
//   };

//   const handleDelete = async (id) => {
//     const confirmDelete = await Swal.fire({
//       title: 'Are you sure?',
//       text: 'You will not be able to recover this data!',
//       icon: 'warning',
//       showCancelButton: true,
//       confirmButtonColor: '#d33',
//       cancelButtonColor: '#3085d6',
//       confirmButtonText: 'Yes, delete it!',
//     });

//     if (confirmDelete.isConfirmed) {
//       try {
//         setLoading(true);
//         await axios.delete(`https://testscanner.workrspot.com/delete-form/${id}`);
//         // await axios.delete(`http://localhost:5000/delete-form/${id}`);
//         fetchData();
//         setLoading(false);
//         Swal.fire({
//           icon: 'success',
//           title: 'Data Deleted!',
//           showConfirmButton: false,
//           timer: 1500,
//         });
//       } catch (error) {
//         console.error(error);
//         setLoading(false);
//       }
//     }
//   };





//   const handleCommentChange = (e) => {
//     setCommentInput(e.target.value);
// };


// const handleAddComment = async (userId) => {
//   try {
//     setLoading(true);
//     // Extract userId from the object
//     const id = userId.userId;
//     // console.log('Adding comment:', commentInput, 'for user ID:', id);
//     const response = await axios.post(`https://testscanner.workrspot.com/update-comment/${id}`, { comment: commentInput });
//     // const response = await axios.post(`http://localhost:5000/update-comment/${id}`, { comment: commentInput });
//     // console.log('Response:', response.data);
//     const updatedFormData = formData.map(data => {
//       if (data.id === id) {
//         return { ...data, comment: commentInput };
//       }
//       return data;
//     });
//     setFormData(updatedFormData);
//     setCommentInput('');
//     setLoading(false);
//     Swal.fire({
//       icon: 'success',
//       title: 'Comment Added!',
//       showConfirmButton: false,
//       timer: 1500,
//     });
//   } catch (error) {
//     console.error(error);
//     setLoading(false);
//     Swal.fire({
//       icon: 'error',
//       title: 'Error',
//       text: 'Failed to add comment',
//     });
//   }
// };

  

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };



  

//   const handleViewDocument = async (documentName, userName, userId) => {
//     try {
//       setPdfLoading(true);    
//       // const response = await axios.get(`http://localhost:5000/get-pdf/${documentName}`, {
//       const response = await axios.get(`https://testscanner.workrspot.com/get-pdf/${documentName}`, {
//         responseType: 'blob',
//       });
//       const extension = documentName.split('.').pop().toLowerCase();
//       let mimeType = '';
  
//       if (['pdf'].includes(extension)) {
//         mimeType = 'application/pdf';
//       } else if (['jpg', 'jpeg', 'png'].includes(extension)) {
//         mimeType = `image/${extension}`;
//       } else {
//         // Handle other file types here
//         mimeType = 'application/octet-stream';
//       }
  
//       const file = new Blob([response.data], { type: mimeType });
//       const fileURL = URL.createObjectURL(file);
      
//       setSelectedDocument({ userId, fileURL }); // Set userId and fileURL in state
//       setPdfLoading(false);
//       setSelectedUserName(userName);
//       setViewPdfTab(true);
//       setActiveTab('user-documents'); 
//     } catch (error) {
//       console.error(error);
//       setPdfLoading(false);
//     }
//   };
  
//   const handleBackToUserDetails = () => {
//     setViewPdfTab(false);
//     setActiveTab('user-details'); // Switch back to 'User Details' tab
//   };

//   if (!user) {
//     return null;
//   }

//   const columns = [
//     { field: 'id', headerName: 'ID', width: 20 },
//     { field: 'fullname', headerName: 'Full Name', width: 150 },
//     { field: 'email', headerName: 'Email', width: 200 },
//     { field: 'phone', headerName: 'Phone', width: 100 },
//     { field: 'original_filename', headerName: 'Document', width: 250 },
//     { field: 'comment', headerName: 'Comment', width: 250 },
//     {
//       field: 'actions',
//       headerName: 'Actions',
//       width: 300,
//       renderCell: (params) => (
//         <Grid container spacing={1} alignItems="center">
//           <Grid item>
//           <Button
//   variant="contained"
//   color="primary"
//   size="small"
//   onClick={() => handleViewDocument(params.row.document, params.row.fullname, params.row.id)}
// >
//   View PDF
// </Button>

//           </Grid>
//           <Grid item>
//             <Button variant="contained" color="error" size="small" onClick={() => handleDelete(params.row.id)}>
//               Delete
//             </Button>
//           </Grid>
//           <Grid item>
//             <FormControl variant="outlined" size="small">
//               <OutlinedInput
//                 placeholder="Add comment"
//                 value={commentInput || comments[params.row.id] || ''}
//                 onChange={(e) => handleCommentChange(e, params.row.id)}
//               />
//             </FormControl>
//           </Grid>
//           <Grid item>
// <Button variant="contained" color="primary" size="small" onClick={() => handleAddComment(selectedDocument.userId)}>
//   Add Comment
// </Button>


//           </Grid>
//           <Grid item>
//             {params.row.comment ? (
//               <Typography variant="body2">{params.row.comment}</Typography>
//             ) : (
//               <Typography variant="body2" color="textSecondary">No comment</Typography>
//             )}
//           </Grid>
//         </Grid>
//       ),
      
//     },
//   ];

//   const filteredData = formData.filter((data) =>
//     data.fullname && data.fullname.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

//   return (
//     <div>
//       <AppBar position='static'>
//         <Toolbar>
//           <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
//             Dashboard
//           </Typography>
//           <div className='search-bar'>
//             <div className='search-icon'>
//               <FontAwesomeIcon icon={faSearch} />
//               <InputBase
//                 placeholder='Search by Full Name'
//                 onChange={handleSearch}
//                 className='ms-2'
//                 value={searchTerm}
//               />
//             </div>
//           </div>
//           <IconButton color='inherit' className="fs-6" onClick={handleLogout}>
//             Logout
//           </IconButton>
//         </Toolbar>
//       </AppBar>
//       <Container maxWidth="xxl">
//         <Paper sx={{ mt: 3 }}>
//           <Tabs defaultActiveKey='user-details' activeKey={activeTab} id='dashboard-tabs'>
//             <Tab eventKey='user-details' title='User Details'>
//               <div style={{ height: 600, width: '100%' }}>
//                 {showSkeleton ? (
//                   <Box sx={{ width: '100%' }}>
//                     <Skeleton />
//                     <Skeleton animation="wave" />
//                     <Skeleton animation={false} />
//                   </Box>
//                 ) : (
//                   <DataGrid
//                     rows={currentItems}
//                     columns={columns}
//                     pagination
//                     pageSize={itemsPerPage}
//                     rowCount={filteredData.length}
//                     paginationMode='server'
//                     onPageChange={(page) => setCurrentPage(page)}
//                   />
//                 )}
//               </div>
//             </Tab>
//             <Tab eventKey='user-documents' title='User Documents'>
//               <div style={{ marginTop: 16 }}>
//                 {viewPdfTab ? (
//                   <div>
//                     <Button variant="contained" onClick={handleBackToUserDetails}>
//                       Back
//                     </Button>
//                     <Typography variant="h5" align="center" gutterBottom>
//                       {selectedUserName}
//                     </Typography>
//                     <div style={{ marginTop: 16 }}>
//   {pdfLoading ? (
//     <div className='text-center'>
//       <Spinner animation='border' role='status'>
//         <span className='visually-hidden'>Loading...</span>
//       </Spinner>
//     </div>
//   ) : (
//     <>
//       {selectedDocument && (
//         <iframe src={selectedDocument.fileURL} title='document' width='100%' height='600px' />
//       )}
//     </>
//   )}
// </div>

//                     <div style={{ marginTop: 16 }}>
//                       <FormControl variant="outlined" fullWidth>
//                         <InputLabel htmlFor="comment-textarea">Add Comment</InputLabel>
//                         <OutlinedInput
//                           id="comment-textarea"
//                           multiline
//                           rows={4}
//                           value={commentInput}
//                           onChange={handleCommentChange}
//                         />
//                       </FormControl>
//                       <Button variant="contained" color="primary" onClick={() => handleAddComment(selectedDocument)} style={{ marginTop: 16 }}>
//                         Add Comment
//                       </Button>
//                     </div>
//                   </div>
//                 ) : (
                  
//                   <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
//                   </div>
//                 )}
//               </div>
//             </Tab>
//           </Tabs>
//         </Paper>
//       </Container>
//     </div>
//   );
// };

// export default Dashboard;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { DataGrid } from '@mui/x-data-grid';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  InputBase,
  Grid,
  Container,
  Paper,
  FormControl,
  OutlinedInput,
  Button,
  TextField,
  InputLabel,
} from '@mui/material';

const Dashboard = () => {
  const { user, logout, setUser } = useAuth();
  const [formData, setFormData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [documentLoading, setDocumentLoading] = useState({});
  const [itemsPerPage] = useState(10);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [comments, setComments] = useState({});
  const [Loading, setLoading] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [viewPdfTab, setViewPdfTab] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false); // Track PDF loading
  const [selectedUserName, setSelectedUserName] = useState('');
  const [commentSortOrder, setCommentSortOrder] = useState('desc');
  const [activeTab, setActiveTab] = useState('user-details'); // Active tab state
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      navigate('/login');
    }
  }, [navigate, setUser]);

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://testscanner.workrspot.com/get-forms');
      const formData = response.data || [];

      const commentPromises = formData.map(async (data) => {
        const commentResponse = await axios.get(`https://testscanner.workrspot.com/get-comment/${data.id}`);
        return { ...data, comment: commentResponse.data };
      });

      const formDataWithComments = await Promise.all(commentPromises);

      setFormData(formDataWithComments);
      setLoading(false);
      setShowSkeleton(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    logout();
    navigate('/login');
  };

  const handleDelete = async (id) => {
    const confirmDelete = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (confirmDelete.isConfirmed) {
      try {
        setLoading(true);
        await axios.delete(`https://testscanner.workrspot.com/delete-form/${id}`);
        fetchData();
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Data Deleted!',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  const handleCommentChange = (e) => {
    setCommentInput(e.target.value);
  };

  const handleAddComment = async (userId) => {
    try {
      setLoading(true);
      const id = userId.userId;
      const response = await axios.post(`https://testscanner.workrspot.com/update-comment/${id}`, { comment: commentInput });
      const updatedFormData = formData.map(data => {
        if (data.id === id) {
          return { ...data, comment: commentInput };
        }
        return data;
      });
      setFormData(updatedFormData);
      setCommentInput('');
      setLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'Comment Added!',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add comment',
      });
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // const handleViewDocument = async (documentName, userName, userId) => {
  //   try {
  //     setPdfLoading(true);
  //     const response = await axios.get(`https://testscanner.workrspot.com/get-pdf/${documentName}`, {
  //       responseType: 'blob',
  //     });
  //     const extension = documentName.split('.').pop().toLowerCase();
  //     let mimeType = '';
  
  //     if (['pdf'].includes(extension)) {
  //       mimeType = 'application/pdf';
  //     } else if (['jpg', 'jpeg', 'png'].includes(extension)) {
  //       mimeType = `image/${extension}`;
  //     } else {
  //       mimeType = 'application/octet-stream';
  //     }
  
  //     const file = new Blob([response.data], { type: mimeType });
  //     const fileURL = URL.createObjectURL(file);
      
  //     setSelectedDocument({ userId, fileURL });
  //     setPdfLoading(false);
  //     setSelectedUserName(userName);
  //     setViewPdfTab(true);
  //     setActiveTab('user-documents'); 
  //   } catch (error) {
  //     console.error(error);
  //     setPdfLoading(false);
  //   }
  // };


  const handleViewDocument = async (documentName, userName, userId) => {
    try {
      setDocumentLoading(prevState => ({ ...prevState, [userId]: true })); // Set loading state for this document
      const response = await axios.get(`https://testscanner.workrspot.com/get-pdf/${documentName}`, {
        responseType: 'blob',
      });
      const extension = documentName.split('.').pop().toLowerCase();
      let mimeType = '';
  
      if (['pdf'].includes(extension)) {
        mimeType = 'application/pdf';
      } else if (['jpg', 'jpeg', 'png'].includes(extension)) {
        mimeType = `image/${extension}`;
      } else {
        mimeType = 'application/octet-stream';
      }
  
      const file = new Blob([response.data], { type: mimeType });
      const fileURL = URL.createObjectURL(file);
      
      setSelectedDocument({ userId, fileURL });
      setSelectedUserName(userName);
      setViewPdfTab(true);
      setActiveTab('user-documents'); 
    } catch (error) {
      console.error(error);
    } finally {
      setDocumentLoading(prevState => ({ ...prevState, [userId]: false })); 
    }
  };
  
  
  const handleBackToUserDetails = () => {
    setViewPdfTab(false);
    setActiveTab('user-details');
  };

  if (!user) {
    return null;
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 20 },
    { field: 'fullname', headerName: 'Full Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 100 },
    { field: 'original_filename', headerName: 'Document', width: 250 },
    { field: 'comment', headerName: 'Comment', width: 250 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
          <Button
  variant="contained"
  color="primary"
  size="small"
  onClick={() => handleViewDocument(params.row.document, params.row.fullname, params.row.id)}
>
  {documentLoading[params.row.id] ? (
    <Spinner animation='border' role='status' size="sm">
      <span className='visually-hidden'>Loading...</span>
    </Spinner>
  ) : (
    "View Document"
  )}
</Button>

          </Grid>
          <Grid item>
            <Button variant="contained" color="error" size="small" onClick={() => handleDelete(params.row.id)}>
              Delete
            </Button>
          </Grid>
          <Grid item>
            <FormControl variant="outlined" size="small">
              <OutlinedInput
                placeholder="Add comment"
                value={commentInput || comments[params.row.id] || ''}
                onChange={(e) => handleCommentChange(e, params.row.id)}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" size="small" onClick={() => handleAddComment(params.row)}>
              Add Comment
            </Button>
          </Grid>
          <Grid item>
            {params.row.comment ? (
              <Typography variant="body2">{params.row.comment}</Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">No comment</Typography>
            )}
          </Grid>
        </Grid>
      ),
    },
  ];

  const filteredData = formData.filter((data) =>
    data.fullname && data.fullname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <div className='search-bar'>
            <div className='search-icon'>
              <FontAwesomeIcon icon={faSearch} />
              <InputBase
                placeholder='Search by Full Name'
                onChange={handleSearch}
                className='ms-2'
                value={searchTerm}
              />
            </div>
          </div>
          <IconButton color='inherit' className="fs-6" onClick={handleLogout}>
            Logout
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xxl">
        <Paper sx={{ mt: 3 }}>
          <Tabs defaultActiveKey='user-details' activeKey={activeTab} id='dashboard-tabs'>
            <Tab eventKey='user-details' title='User Details'>
              <div style={{ height: 600, width: '100%' }}>
                {showSkeleton ? (
                  <Box sx={{ width: '100%' }}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                  </Box>
                ) : (
                  <DataGrid
                    rows={currentItems}
                    columns={columns}
                    pagination
                    pageSize={itemsPerPage}
                    rowCount={filteredData.length}
                    paginationMode='server'
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </div>
            </Tab>
            <Tab eventKey='user-documents' title='User Documents'>
              <div style={{ marginTop: 16 }}>
                {viewPdfTab ? (
                  <div>
                    <Button variant="contained" onClick={handleBackToUserDetails}>
                      Back
                    </Button>
                    <Typography variant="h5" align="center" gutterBottom>
                      {selectedUserName}
                    </Typography>
                    <div style={{ marginTop: 16 }}>
                    {pdfLoading ? (
  <div className='text-center'>
    <Spinner animation='border' role='status'>
      <span className='visually-hidden'>Loading...</span>
    </Spinner>
  </div>
) : (
  <>
    {selectedDocument && (
      <iframe src={selectedDocument.fileURL} title='document' width='100%' height='600px' />
    )}
  </>
)}
                    </div>
                    <div style={{ marginTop: 16 }}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="comment-textarea">Add Comment</InputLabel>
                        <OutlinedInput
                          id="comment-textarea"
                          multiline
                          rows={4}
                          value={commentInput}
                          onChange={(e) => handleCommentChange(e)}
                        />
                      </FormControl>
                      <Button variant="contained" color="primary" onClick={() => handleAddComment(selectedDocument)} style={{ marginTop: 16 }}>
                        Add Comment
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </Paper>
      </Container>
    </div>
  );
};

export default Dashboard;
