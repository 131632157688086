import React, { useState } from 'react';
import './App.css'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthContext';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { login } = useAuth();
    const navigate = useNavigate();

    

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
        //   const response = await axios.post('http://localhost:5000/login',
        //   const response = await axios.post('https://testscanner.workrspot.com/login',
          const response = await axios.post('https://testscanner.workrspot.com/login',
          // const response = await axios.post('http://localhost:5000/login',
           { username, password });
          if (response.data.success) {
            login(response.data.token);
            navigate('/dashboard');
          } else {
            setError('Invalid credentials');
          }
        } catch (error) {
          setError('Invalid credentials');
        }finally {
            setLoading(false);
          }
      };
    return (
        <div className="login-container">
            <form className="form" onSubmit={handleLogin}>
                <p className="title">Login</p>
                <p className="message">Log in to access your account.</p>

                <label>
                    <input
                        type="username"
                        className="input"
                        placeholder=""
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <span>Username</span>
                </label>

                <label>
                    <input
                        type="password"
                        className="input"
                        placeholder=""
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <span>Password</span>
                </label>

                {error && <p style={{ color: 'red' }}>{error}</p>}

                <button className="submit" type="submit" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>

            
            </form>
        </div>
    );
};

export default Login;
