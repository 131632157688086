import React from 'react';
import { BrowserRouter , Routes,  Route , Navigate } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import Form from './Form';
import { AuthProvider } from './AuthContext';



const App = () => {
  return (
    <AuthProvider>
    <BrowserRouter>
    <Routes>

        {/* Independent route for Signup */}
        <Route path="/" element={<Form />} />

        {/* Independent route for Login */}
        <Route path="/login" element={<Login />} />
        {/* <Route path="/" element={<Navigate to="/login" />} /> */}
        {/* Independent route for Dashboard */}
        <Route path="/dashboard" element={<Dashboard />} />
        

    </Routes>
</BrowserRouter>
</AuthProvider>
  );
};

export default App;
