// import React, { useState } from 'react';
// import axios from 'axios';

// const Form = () => {
//   const [fullname, setFullname] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [document, setDocument] = useState(null);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append('fullname', fullname);
//     formData.append('email', email);
//     formData.append('phone', phone);
//     formData.append('document', document);

//     try {
//       await axios.post('http://localhost:5000/submit-form', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       alert('Form submitted successfully');
//     } catch (error) {
//       console.error(error);
//       alert('Error submitting form');
//     }
//   };

//   return (
//     <div>
//       <h1>Form</h1>
//       <form onSubmit={handleSubmit}>
//         <input type="text" placeholder="Full Name" value={fullname} onChange={(e) => setFullname(e.target.value)} />
//         <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
//         <input type="tel" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
//         <input type="file" accept=".pdf,.doc,.docx" onChange={(e) => setDocument(e.target.files[0])} />
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// };

// export default Form;


// import React, { useState } from 'react';
// import axios from 'axios';

// const Form = () => {
//   const [fullname, setFullname] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [document, setDocument] = useState(null);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const formData = new FormData();
//     formData.append('fullname', fullname);
//     formData.append('email', email);
//     formData.append('phone', phone);
//     formData.append('document', document);

//     try {
//       await axios.post('http://localhost:5000/submit-form', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       alert('Form submitted successfully');
//       // Clear form fields after successful submission
//       setFullname('');
//       setEmail('');
//       setPhone('');
//       setDocument(null);
//     } catch (error) {
//       console.error(error);
//       alert('Error submitting form');
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <h1 className="mb-4">Form</h1>
//       <div className='col-5'>

      
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <input type="text" className="form-control" placeholder="Full Name" value={fullname} onChange={(e) => setFullname(e.target.value)} required />
//         </div>
//         <div className="form-group">
//           <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
//         </div>
//         <div className="form-group">
//           <input type="tel" className="form-control" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
//         </div>
//         <div className="form-group">
//           <div className="custom-file">
//             <input type="file" className="custom-file-input" id="document" accept=".pdf,.doc,.docx" onChange={(e) => setDocument(e.target.files[0])} required />
//             <label className="custom-file-label" htmlFor="document">
//               {document ? document.name : 'Choose document'}
//             </label>
//           </div>
//         </div>
//         <button type="submit" className="btn btn-primary">Submit</button>
//       </form>
//       </div>
//     </div>
//   );
// };

// export default Form;


// import React, { useState } from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import  New from './new.jpg';

// const Form = () => {
//   const [fullname, setFullname] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [document, setDocument] = useState(null);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Phone number validation
//     if (!phone.match(/^\d{10}$/)) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Invalid Phone Number',
//         text: 'Please enter a valid 10-digit phone number.',
//       });
//       return;
//     }

//     // Document type validation
//     if (!document || !['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(document.type)) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Invalid Document Type',
//         text: 'Please upload a PDF, DOC, or DOCX file.',
//       });
//       return;
//     }

//     const formData = new FormData();
//     formData.append('fullname', fullname);
//     formData.append('email', email);
//     formData.append('phone', phone);
//     formData.append('document', document);

//     try {
//       await axios.post('https://testscanner.workrspot.com/submit-form', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       // Sweet Alert notification
//       Swal.fire({
//         icon: 'success',
//         title: 'Form Submitted Successfully',
//         timer: 2000,
//         timerProgressBar: true,
//         showConfirmButton: false
//       }).then(() => {
//         // Clear form fields after successful submission
//         setFullname('');
//         setEmail('');
//         setPhone('');
//         setDocument(null);
//         // Reload the page
//         window.location.reload();
//       });
//     } catch (error) {
//       console.error(error);
//       // Sweet Alert error notification
//       Swal.fire({
//         icon: 'error',
//         title: 'Error',
//         text: 'Failed to submit form. Please try again.',
//       });
//     }
//   };

//   return (
//     <div className="container mt-5">
//       <img src={New}/>
//       <h1 className="mb-4 text-center">Form</h1>
//       <div className="row">
//         <div className="col-lg-6 mx-auto">
//           <form onSubmit={handleSubmit}>
//             <div className="form-group">
//               <label htmlFor="fullname">Full Name</label>
//               <input type="text" className="form-control" id="fullname" placeholder="Enter your full name" value={fullname} onChange={(e) => setFullname(e.target.value)} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="email">Email</label>
//               <input type="email" className="form-control" id="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required />
//             </div>
//             <div className="form-group">
//               <label htmlFor="phone">Phone</label>
//               <input type="tel" className="form-control" id="phone" placeholder="Enter your phone number (10 digits)" value={phone} onChange={(e) => setPhone(e.target.value)} required />
              
//             </div>
//             <div className="form-group">
//               <label htmlFor="document">Document</label>
//               <div className="custom-file">
//                 <input type="file" className="custom-file-input" id="document" accept=".pdf,.doc,.docx" onChange={(e) => setDocument(e.target.files[0])} required />
//                 <label className="custom-file-label" htmlFor="document">
//                   {document ? document.name : 'Choose document'}
//                 </label>
//               </div>
//             </div>
//             <button type="submit" className="btn btn-primary mt-3 btn-block">Submit</button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Form;

import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import New from './new.jpg';
import './form.css';

const Form = () => {
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Phone number validation
    if (!phone.match(/^\d{10}$/)) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Invalid Phone Number',
        text: 'Please enter a valid 10-digit phone number.',
      });
      return;
    }

    // // Document type validation
    // if (!document || !['png' ,'jpeg' ,'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(document.type)) {
    //   setLoading(false);
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Invalid Document Type',
    //     text: 'Please upload a PDF, DOC, or DOCX file.',
    //   });
    //   return;
    // }

    const formData = new FormData();
    formData.append('fullname', fullname);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('document', document);

    try {
      await axios.post('https://testscanner.workrspot.com/submit-form', formData, {
      // await axios.post('http://localhost:5000/submit-form', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Sweet Alert notification
      Swal.fire({
        icon: 'success',
        title: 'Form Submitted Successfully',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false
      }).then(() => {
        // Clear form fields after successful submission
        setFullname('');
        setEmail('');
        setPhone('');
        setDocument(null);
        setLoading(false);
        // Reload the page
        window.location.reload();
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      // Sweet Alert error notification
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to submit form. Please try again.',
      });
    }
  };

  return (
    <div>
      <img src={New} className="bg-image" alt="background" />
      <div className="container mt-5">
        <h1 className="mb-4 text-center">Form</h1>
        <div className="row">
          <div className="col-lg-6 mx-auto">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="fullname">Full Name</label>
                <input type="text" className="form-control" id="fullname" placeholder="Enter your full name" value={fullname} onChange={(e) => setFullname(e.target.value)} required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" className="form-control" id="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input type="tel" className="form-control" id="phone" placeholder="Enter your phone number (10 digits)" value={phone} onChange={(e) => setPhone(e.target.value)} required />
              </div>
              {/* <div className="form-group">
                <label htmlFor="document">Document</label>
                <div className="custom-file">
                  <input type="file" className="custom-file-input" id="document" accept=".pdf,.doc,.docx,.png ,.jpeg" onChange={(e) => setDocument(e.target.files[0])} required />
                </div>
              </div> */}
              <div className="form-group">
  <label htmlFor="document">Document</label>
  <div className="custom-file">
    <input
      type="file"
      className="custom-file-input"
      id="document"
      onChange={(e) => setDocument(e.target.files[0])}
      required
    />
    {/* <label className="custom-file-label" htmlFor="document">
      Choose file
    </label> */}
  </div>
</div>

              <div className='text-center'>
                <button type="submit" className="btn btn-primary mt-3 btn-block" disabled={loading}>
                  {loading ? <div className="spinner-border" role="status"></div> : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
